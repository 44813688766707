<!-- @Author: xiaoyaqu -->
<!-- @Date: 2019/9/18-20:08 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-11-13 16:37:21 -->

<template>
    <div
        class="agreement"
        :class="{
            'sign-padding': signBtnVisible || confirmBtnVisible
        }"
    >
        <yqg-md-preview :md-text="content" />
        <agreement-confirm-btn
            v-if="confirmBtnVisible"
            :is-bottom="isBottom"
            :at-bottom="atBottom"
            :is-plan-b="isPlanB"
        />
        <agreement-download-btn />
        <agreement-sign-btn v-if="signBtnVisible" />
    </div>
</template>

<script type="text/babel">
import AgreementConfirmBtn from 'easycash/common/component/ec-agreement-confirm-btn';
import AgreementDownloadBtn from 'easycash/common/component/ec-agreement-download-btn';
import AgreementSignBtn from 'easycash/common/component/ec-agreement-sign-btn';
import asyncContentManage from 'easycash/common/mixin/async-content-manage';
import Agreement from 'easycash/common/resource/agreement';

export default {
    name: 'Agreement',

    components: {
        AgreementConfirmBtn,
        AgreementDownloadBtn,
        AgreementSignBtn
    },

    mixins: [asyncContentManage({query: Agreement.getByKey})],

    inject: ['uploadClickEvent'],

    data() {
        return {
            defaultTitle: 'Agreement'
        };
    },

    computed: {
        isPlanB() {
            return this.$route.query.confirm?.toLowerCase() === 'true';
        }
    },

    mounted() {
        if (this.isPlanB) {
            this.uploadClickEvent('EasyCashAgreementPageEnter', {
                type: 'B'
            });
        }
    }

};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
div.agreement {
    padding: 20px;

    img {
        max-width: 100%;
    }

    &.sign-padding {
        padding-bottom: 1.25rem;
    }
}
</style>
