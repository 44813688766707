var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "agreement",
      class: {
        "sign-padding": _vm.signBtnVisible || _vm.confirmBtnVisible,
      },
    },
    [
      _c("yqg-md-preview", { attrs: { "md-text": _vm.content } }),
      _vm._v(" "),
      _vm.confirmBtnVisible
        ? _c("agreement-confirm-btn", {
            attrs: {
              "is-bottom": _vm.isBottom,
              "at-bottom": _vm.atBottom,
              "is-plan-b": _vm.isPlanB,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("agreement-download-btn"),
      _vm._v(" "),
      _vm.signBtnVisible ? _c("agreement-sign-btn") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }